<template>
    <v-container
      fluid
      tag="section"
    >
      <material-card
        color="primary"
        icon="mdi-watermark"
      >
        <template #title v-if="brand.id">
          Бренд: <b>{{ brand.name }}</b> <br>
        </template>
        <template #title v-else>
          Новий бренд
        </template>
  
        <ButtonComeBack class="ml-3 mb-3" />
    
          <v-row>
            <v-col cols="12" lg="6">
              <v-form class="form-brand-edit" lazy-validation v-model="isValidForm" ref="formBrand">
                <!--Включено-->
                <v-col cols="12">
                  <v-checkbox
                    v-model="brand.enabled"
                    label="Включено"
                  ></v-checkbox>
                </v-col>
  
                <!--Содержымое табов-->
                <v-col cols="12">
                        <!--Название-->
                        <v-text-field
                          label="Назва"
                          v-model="brand.name"
                          required
                          :rules="[v => !!v || errorRequired]"
                        />
                        <!--Картинка-->
                        <ImageUpload 
                          v-model="brandImage" 
                          @image-upload:change="changeImageList($event, 'ua')" 
                          :imageBaseUrl="imageBaseUrl"
                        />
                </v-col>
                
                <!--Кнопки-->
                <v-col cols="12" class="text-right">
                  <v-btn 
                    color="primary" 
                    min-width="150" 
                    @click="updateBrand()">
                    Зберегти
                  </v-btn>
                </v-col>
                <Preloader v-if="isSending" />
              </v-form>
              
            </v-col>
          </v-row>
      </material-card>
    </v-container>
  </template>
  
  
  <script>
  import { HTTP } from '@/http-common';
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import { IMAGE_BASE_URL } from '@/env.config';
   
  export default {
    name: 'PageBrandProfile',
    data() {
      return {
        enabled: false,
        brand: {},
        // Нужно vuetify для валидации формы
        isValidForm: true,
        // Сообщение об ошибке для обязательных полей
        errorRequired: `Це поле обов'язкове`,
        // Указывает на то что выполняется отправка формы
        isSending: false,
        // Изображения для отправки 
        imagesToSend: {},
        brandImage: null,//'/assets/img/brands/2v9aj5numrmm1g710skx3wmy0wt3m5sp.png',
        // Базовый url картинки
        imageBaseUrl: `${IMAGE_BASE_URL}brands/`
      }
    },
    computed: {
      ...mapGetters(['snackbar', 'localization', 'isManager', 'isAdmin'])
    },
    async mounted() {
      await this.init();
    },
    watch: {
    },
    methods: {
      ...mapMutations(['setSnackbar']),
      async init() {
        if (Number(this.$route.params.id)){
          const data = await this.getBrand();
          this.brandImage = data.image ? this.imageBaseUrl + data.image : null;
        }
      },
  
      async getBrand() {

        let { data } = await HTTP.post('/get-brand', { 'id': this.$route.params.id  });
        this.brand = data.content;
        return data.content;
      },
  
      // Изменить список картинок 
      changeImageList(data, langKey) {
        if (data.action) {
          this.imagesToSend[langKey] = {
            sendImage: data.sendImage
          };
        } else {
          delete this.imagesToSend[langKey];
        }
      },
  
      // Создать список ошибок
      createListErrors() {
        let errors = '';
        for(let item of this.actionType) {
          if (!item.name) {
            errors += `Помилка</br>`;
          }
        }
        return errors;
      },

  
      async updateBrand() {
        let queryParams = this.brand;
  
        if (this.isSending) return;
  
        if (!this.$refs.formBrand.validate()) {
          this.setSnackbar({ type: 'error', text: this.createListErrors() });
          return;
        }
  
        this.isSending = true;
  
        try {
          const {data} = await HTTP.post(this.brand.id ? '/update-brand' : '/create-brand', queryParams);

          let isImages = Object.keys(this.imagesToSend).length;
  
          this.setSnackbar({ 
            type: 'success', 
            text: `Данні оновлено. ${ isImages ? 'Виконується завантаження зображень.': '' }` 
          });
  
          if (!isImages) {
            this.isSending = false;
          }
          // сделал через $watch потомучто setSnackbar сделан через "mutation" (не может вернуть промис)
          const unwatch = this.$watch('snackbar.model', function (newVal, oldVal) {
            unwatch();
            // Запускаем загрузку изображений
            if (isImages) {
              this.runProcessImagesSend(this.brand.id || data.content.insertId);
            }
          });
        } catch(error) {
          console.warn(error);
          this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
          this.isSending = false;
        }
      },
  
      // Запускает процесс отравки изображений, отправкой занимается "ImageUpload"
      async runProcessImagesSend(id) {
        let errors = '';
        for (let key in this.imagesToSend) {
          let item = this.imagesToSend[key];
          let queryParams = {
            lang_key: key,
            object_image_id: id,
            image_type: 'brand_image',
          };
  
          let result = await item.sendImage(queryParams);
          // Если при загрузке изображений были - показать попап
          if (!result.error) {
            delete this.imagesToSend[key];
          } else {
            errors += `${result.error}</br>`;
            this.setSnackbar({ type: 'error', text: errors });
          }
        }
        this.isSending = false;
      },
    },
  }
  </script>
  
  <style scoped lang="sass">
  .form-brand-edit
    position: relative
  </style>
